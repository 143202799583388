import { AiOutlineClose } from "react-icons/ai";
import React from "react";
import abcam from "../assets/images/placeholder.bmp";
import abt from "../assets/images/abt.jpeg";
import activemotif from "../assets/images/activemotif.png";
import antibodies from "../assets/images/antibodies.com.png";
import candor from "../assets/images/candor.png";
import classes from "./CartItem.module.css";
import cyanagen from "../assets/images/cyanagen.png";
import everest from "../assets/images/everestLogo.png";
import fntest from "../assets/images/fntestLogo.png";
import genedirex from "../assets/images/genedirexLogo.png";
import ict from "../assets/images/ict.jpg";
import idt from "../assets/images/idt.png";
import invitek from "../assets/images/invitekLogo.png";
import lgcLogo from "../assets/images/lgcLogo.png";
import mileniaLogo from "../assets/images/mileniaLogo.png";
import molnova from "../assets/images/molnovaLogo.png";
import ontores from "../assets/images/ontoresLogo.png";
import pikaLogo from "../assets/images/pikaLogo.png";
import primerdesign from "../assets/images/primerdesign.png";
import rodo from "../assets/images/RODO.png";
import starlab from "../assets/images/starlabLogo.png";
import targetmol from "../assets/images/targetmolLogo.png";
import unitma from "../assets/images/unitmaLogo.png";
import uvp from "../assets/images/uvpLogo.webp";
import zellxLogo from "../assets/images/zellx.png";
// import lucigen from "../assets/images/lucigen.jpeg";

function CartItem(props) {
  const deleteItem = () => {
    // const itemIndex = props.cartInfo.findIndex((element) => {
    //   return element.sku === props.item.sku;
    // });

    props.setCartInfo((current) => current.filter((element) => element.sku !== props.item.sku));
  };

  const changeProductAmount = (operation) => {
    if (operation === "ADD") {
      let newObject = props.cartInfo.map((item) => {
        if (item.sku === props.item.sku) {
          return { ...item, amount: item.amount + 1 };
        }
        return item;
      });
      props.setCartInfo(newObject);
    } else {
      let newObject = props.cartInfo.map((item) => {
        if (item.sku === props.item.sku) {
          if (item.amount === 1) return {};
          return { ...item, amount: item.amount - 1 };
        }
        return item;
      });
      newObject = newObject.filter((value) => Object.keys(value).length !== 0);
      props.setCartInfo(newObject);
    }
  };

  const updateProductAmount = () => {
    const newObject = props.cartInfo.map((item) => {
      if (item.sku === props.item.sku) {
        return { ...item, amount: item.amount + 1 };
      }
      return props.cartInfo;
    });

    props.setCartInfo(newObject);
  };

  return (
    <div className={classes.cartItem__positioner}>
      <div className={classes.cartItem__container}>
        <img
          className={classes.cartItem__image}
          src={
            props.item.producent === "RODO"
              ? rodo
              : props.item.producent === "Antibodies.com"
              ? antibodies
              : props.item.producent === "TargetMol"
              ? targetmol
              : props.item.producent === "Active Motif"
              ? activemotif
              : props.item.producent === "ABT"
              ? abt
              : props.item.producent === "Candor"
              ? candor
              : props.item.producent === "Cyanagen"
              ? cyanagen
              : props.item.producent === "Everest"
              ? everest
              : props.item.producent === "FineTest"
              ? fntest
              : props.item.producent === "Genedirex"
              ? genedirex
              : props.item.producent === "ICT"
              ? ict
              : props.item.producent === "IDT"
              ? idt
              : props.item.producent === "PIKA Weihenstephan GmbH"
              ? pikaLogo
              : props.item.producent === "Ontores"
              ? ontores
              : props.item.producent === "PrimerDesign"
              ? primerdesign
              : props.item.producent === "Starlab"
              ? starlab
              : props.item.producent === "UVP"
              ? uvp
              : props.item.producent === "Abcam"
              ? abcam
              : props.item.producent === "Molnova"
              ? molnova
              : props.item.producent === "Invitek"
              ? invitek
              : props.item.producent === "Unitma"
              ? unitma
              : props.item.producent === "Milenia Biotec GmbH"
              ? mileniaLogo
              : props.item.producent === "LGC"
              ? lgcLogo
              : props.item.producent === "Zellx"
              ? zellxLogo
              : ""
          }
          alt="This is a Producer logo"
        />
        <div className={classes.cartItem__productName}>
          <span>{props.item.title}</span> <span>({props.item.sku})</span>
        </div>
        <div className={classes.cartItem__inputContainer}>
          <div className={classes.cartItem__amount} onClick={() => changeProductAmount("SUBTRACTION")}>
            <span>-</span>
          </div>
          <div className={classes.input__container}>
            <label className={classes["screen__reader--text"]}>ilość PRODUKTU</label>
            <input
              type="number"
              className={classes.cartItem__input}
              size="4"
              min="1"
              max=""
              step="1"
              placeholder=""
              value={props.item.amount}
              onChange={updateProductAmount}
            />
          </div>
          <div
            className={classes.cartItem__amount}
            onClick={() => {
              changeProductAmount("ADD");
            }}
          >
            <span>+</span>
          </div>
        </div>
      </div>
      <div className={classes.cartItem__remove} onClick={deleteItem}>
        <AiOutlineClose className={classes["sidebar__close--fill"]} />
      </div>
    </div>
  );
}

export default CartItem;
